.modal_body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.modal_text {
    font-family: var(--textFontFamily);
    font-size: var(--textFontSize);
    text-align: center;
    margin-bottom: 5%;
}

.mail_icon_wrapper {
    width: 150px;
    text-align: center;
}

.qr_code_icon {
    font-size: 80px;
    width: 100%;
    transform: rotate(-45deg);
    position: relative;
    z-index: 1;
}

.mail_icon {
    margin-top: -28px;
    color: var(--inevvoBlue) !important;
    position: relative;
    z-index: 10;
}

.modal_footer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal_button {
    margin-top: 2%;
    background-color: var(--inevvoGreen) !important;
    border: none !important;
    color: black !important;
    font-family: var(--textFontFamily) !important;
}