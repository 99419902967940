.manufacturer_logo_wrapper {
    text-align: center;
    position: relative;
}

.manufacturer_logo {
    max-width: 25%;
    max-height: 100px;
}

.webview_language_selector {
    margin: 0 !important;
}

@media only screen and (max-width: 768px) {
    .manufacturer_logo {
        max-width: 40%;
        max-height: 80px;
    }
}
