.language_dropdown_wrapper .btn,
.language_dropdown_wrapper .btn:focus,
.language_dropdown_wrapper .btn:hover,
.language_dropdown_wrapper .btn:active {
    background-color: transparent !important;
    color: rgb(0, 0, 0) !important;
    border: none;
    font-size: var(--textFontSize);
}

.language_select_items {
    font-size: var(--textFontSize);
}
.language_select_items:active {
    background-color: rgb(221, 220, 220) !important;
    color: rgb(0, 0, 0) !important;
}
