.form_footer {
    margin-bottom: var(--formItemMargin);
}

.form_save_button {
    color: black !important;
    background-color: var(--inevvoGreen) !important;
    border: none !important;
    font-size: var(--textFontSize) !important;
    display: inline-block !important;
    margin-right: var(--formItemMargin);
}

.checkbox_label {
    margin-left: 5px;
    font-size: var(--checkBoxFontSize);
}

.form_button_wrapper {
    margin-top: var(--formItemMargin);
}

.alert_text {
    margin: 0 0 0 var(--formItemMargin);
    display: inline-block;
}

.privacy_policy {
    margin-top: calc(var(--checkBoxMarginTop) * -1);
}

.checkbox_group {
    display: flex;
    flex-direction: row;
    margin-bottom: var(--checkBoxMargin);
    align-items: center;
}

.email_redirect,
.footer_link {
    text-decoration: underline;
    cursor: pointer;
    color: var(--inevvoBlue);
}

// Checkboxes are displayed on ios devices different
// That is why write this css
.checkbox_input {
    min-width: 15px;
}
