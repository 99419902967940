.error_wrapper {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.error_image {
    max-width: 50%;
    max-height: 50%;
}

.error_text {
    font-size: 2rem;
    font-weight: 600;
    margin-top: 2rem;
    background: -webkit-linear-gradient(#bb2b2b, #fd1745);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
