.document_item_wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: var(--infoItemMargin);
}

.document_info_wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    .info {
        word-break: break-all;
    }
}

.file_icon {
    height: 40px;
    opacity: 0.8;
    margin-right: var(--infoItemMargin);
}
.download_icon_wrapper {
    color: black;
    opacity: 0.3;
    margin-left: 5%;
}

.download_icon {
    height: 25px;
}

@media only screen and (max-width: 768px) {
    .download_icon {
        height: clamp(15px, 3.2vw, 25px);
    }
}
