.input_pair {
    margin-bottom: var(--formItemMargin);
}
.input_label {
    display: block;
    opacity: 0.55;
}

.input_field {
    text-transform: none;
    text-decoration: none;
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    background-color: transparent;
    min-width: 300px;
}
