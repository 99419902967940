.wv_wrapper_1 {
  background-image: url("../../assets/wvBackground.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
}

.wv_wrapper_2 {
  height: 100%;
  min-height: 100vh;
}

.container_wrapper {
  max-width: 1340px;
  margin-right: auto;
  margin-left: auto;
  padding: var(--generalPadding2);
  height: 100%;
}

.info_wrapper {
  background-color: rgba($color: #ffffff, $alpha: 0.3);
  border-radius: 20px;
  padding: var(--infoPadding);
  position: relative;
  padding-bottom: var(--infoPaddingButtom);
}

.container_wrapper > div ~ div {
  margin-top: var(--generalMargin);
}

.info_wrapper > div ~ div {
  margin-top: var(--generalMargin);
}
