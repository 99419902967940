.single_document_group {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.single_document_group:last-child {
    margin-bottom: var(--infoItemMargin);
}

.document_icon_name {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.form_file_icon {
    margin-right: 10px;
    opacity: 0.7;
}
