.footer_wrapper {
    background-color: rgba($color: #ffffff, $alpha: 0.3);
    border-radius: 20px;
    padding: var(--infoPadding);
}

.footer_main {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.footer_info {
    width: 48%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    font-size: var(--textFontSize);
}

.footer_info_title {
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: var(--generalMargin);
    font-size: var(--sectionTitleFontSize2);
}

.editor_footer_info {
    font-size: 5px;
    font-size: var(--textFontSize2);
}

.footer_image_part {
    width: 48%;
}

.footer_logo {
    width: 15%;
    margin-bottom: var(--generalMargin);
}
.footer_image {
    width: 100%;
}

.footer_button {
    border-radius: 20px !important;
    background-color: var(--inevvoGreen) !important;
    border: none !important;
    color: black !important;
    opacity: 0.8;
    margin-top: var(--generalMargin);
    font-size: var(--textFontSize) !important;
}

.editor_footer_button {
    font-size: var(--textFontSize2) !important;
}

@media only screen and (min-width: 769px) and (max-width: 1162px) {
    .editor_footer_main {
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
    }

    .editor_footer_info {
        width: 100%;
    }

    .editor_footer_image_part {
        width: 100%;
        margin-bottom: var(--generalMargin);
    }

    .editor_footer_button {
        font-size: var(--textFontSize2) !important;
    }
}

@media only screen and (max-width: 576px) {
    .footer_main {
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
    }

    .footer_info {
        width: 100%;
        font-size: var(--sectionTitleFontSize);
    }

    .editor_footer_info {
        width: 100%;
        font-size: var(--sectionTitleFontSize2);
    }

    .footer_image_part {
        width: 100%;
        margin-bottom: var(--generalMargin);
    }

    .footer_button {
        font-size: var(--sectionTitleFontSize) !important;
    }

    .editor_footer_button {
        font-size: var(--sectionTitleFontSize2) !important;
    }
}
