.info_part {
    display: flex;
    flex-direction: row;
}

.info_part_title {
    flex: 2;
    font-family: var(--titleFontFamily);
    font-size: var(--sectionTitleFontSize);
}

.info_pair_wrapper {
    flex: 3;
    text-align: left;
}

.info_pair {
    margin-bottom: var(--infoItemMargin);
}

.info_title {
    font-family: var(--textFontFamily);
    font-size: var(--textFontSize);
    opacity: 0.5;
}
.info {
    font-family: var(--textFontFamily);
    font-size: var(--textFontSize);
}

.manufacturer_link {
    text-decoration: none;
    color: black;
}

@media only screen and (max-width: 576px) {
    .info_part_title {
        flex: 1;
    }

    .info_pair_wrapper {
        flex: 2;
    }
}

//   *************editor view*************

.editor_info_part_title {
    font-size: var(--sectionTitleFontSize2);
}

.editor_info_title {
    font-size: var(--textFontSize2);
}

.editor_info {
    font-size: var(--textFontSize2);
}
