.kv_input_wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    max-width: 800px;

    .input_wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }
}
.kv_input {
    min-width: clamp(100px, 45%, 300px);
}

.remove_icon_wrapper {
    background-color: transparent !important;
    border: none !important;
    margin-left: var(--generalMargin);
}

.remove_icon {
    color: rgba(255, 0, 0, 0.6);
    background-color: rgb(255, 255, 255);
    border-radius: 50%;
}

.add_icon_wrapper {
    background-color: transparent !important;
    border: none !important;
}

.add_icon_warning {
    vertical-align: middle;
    color: rgba(255, 0, 0, 0.6);
}

.add_icon {
    color: var(--inevvoGreen);
    background-color: rgb(255, 255, 255);
    border-radius: 50%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .kv_input_wrapper {
        .input_wrapper {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
            width: 100%;
            margin-bottom: var(--infoItemMargin);
        }
    }

    .kv_input {
        min-width: clamp(100px, 100%, 300px);
    }
}
