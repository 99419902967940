.editor_wrapper {
    background-image: url("../../assets/formBackroundImg.png");
    background-size: cover;
}

.editor_wrapper_2 {
    display: flex;
    flex-direction: column;
    background-color: rgba($color: #ffffff, $alpha: 0.96);
    min-height: 100vh;
    // position for language
    position: relative;
}

// language select menu
.language_selector {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0.5rem;
}

.editor_content_wrapper {
    flex: 1;
    display: flex;
}

.editor_form_wrapper {
    flex: 1;
}

.editor_preview_wrapper {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100%;
}

.editor_preview {
    border: 23px solid black;
    outline: 3px solid silver;
    box-shadow: 0 20px 50px 0 rgba(0, 0, 0, 1);
    border-radius: 20px;
    position: fixed;
    overflow-y: scroll;
    max-height: 90vh;
    aspect-ratio: 5/8;
    top: 50%;
    transform: translateY(-50%);
}

::-webkit-scrollbar {
    display: none;
}

@media only screen and (max-width: 1340px) {
    .editor_preview {
        border: clamp(15px, 1.7vw, 23px) solid black;
        outline: 2px solid silver;
    }
}

@media only screen and (max-width: 768px) {
    .editor_preview_wrapper {
        padding: 0var (--generalMargin);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .editor_content_wrapper {
        flex-direction: column;
    }

    .editor_preview {
        position: static;
        top: none;
        transform: none;
        margin: var(--generalMargin);
        top: none;
        transform: none;
        max-height: 90vh;
    }
}
