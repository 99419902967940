.form_wrapper {
    margin: 0 var(--generalMargin);
    margin-top: clamp(10px, 2vw, 20px);
    display: flex;
    flex-direction: column;
}

.form_wrapper > * {
    font-size: var(--textFontSize);
}

#production_date {
    position: relative;
    width: 150px;
    height: 20px;
    color: white;
    padding-bottom: 0.5%;
}

#production_date:before {
    position: absolute;

    content: attr(data-date);
    display: inline-block;
    color: black;
}

#production_date::-webkit-datetime-edit,
#production_date::-webkit-inner-spin-button,
#production_date::-webkit-clear-button {
    display: none;
}

#production_date::-webkit-calendar-picker-indicator {
    position: absolute;
    right: 0;
    color: black;
    opacity: 1;
}