.dropzone_wrapper {
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-bottom: var(--formItemMargin);
    transition: ease-in-out 0.4s;
    background-color: rgba(255, 255, 255, 0.4);
    max-width: 800px;
}

.upload_icon {
    height: clamp(20px, 3vw, 40px);
    opacity: 0.7;
    margin-top: calc(var(--formItemMargin) * 0.5);
}

.drop_text {
    text-align: center;
    margin: calc(var(--formItemMargin) * 0.5);
}
